<template>
  <v-card>
    <v-card-title class="main-red-bg white--text">
      <span class="text-h5">Section Details</span>
    </v-card-title>
    <v-divider class="mb-2"></v-divider>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Section Name</v-list-item-subtitle>
            <v-list-item-title class="title">
              {{ sectionData.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Grade Level</v-list-item-subtitle>
            <v-list-item-title class="title">
              {{ sectionData.gradeLevel }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Adviser</v-list-item-subtitle>
            <v-list-item-title class="title">
              {{ sectionData.teacherName }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card :loading="loadingStudents" v-if="studentsEnrolled.length > 0">
          <v-list tile subheader>
            <v-subheader class="d-flex">
              <v-checkbox
                v-model="selectedAll"
                @click="toggleSelectAll"
                :input-value="selectedAll"
                color="blue lighten-3"
              ></v-checkbox>
              Students
              <v-btn
                outlined
                class="pa-2 ml-auto"
                color="blue"
                @click="openTransferDialog"
              >
                Transfer
              </v-btn>
              <v-dialog v-model="transferDialog" max-width="300px">
                <v-card>
                  <v-card-title class="main-red-bg white--text">
                    <span class="text-h5">Transfer to</span>
                  </v-card-title>
                  <v-divider class="mb-2"></v-divider>
                  <v-card-text>
                    <v-autocomplete
                      v-model="sectionId"
                      label="Section"
                      clearable
                      :items="sectionsAvailable"
                    >
                    </v-autocomplete>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      class="ma-2 white--text px-3"
                      @click="transferDialog = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      :loading="!transferValid"
                      @click="transferSection"
                      color="success"
                      class="ma-2 white--text px-3"
                    >
                      <v-icon left dark> mdi-send </v-icon>
                      Transfer
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-subheader>
            <v-list-item-group
              v-model="selectedStudents"
              multiple
              @change="hasSelectedStudents"
            >
              <template v-for="(student, index) in studentsEnrolled">
                <v-divider
                  v-if="!student"
                  :key="`divider-${index}`"
                ></v-divider>
                <v-list-item
                  v-else
                  :key="`item-${index}`"
                  :value="student.id"
                  active-class="blue--text text--accent-3"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="blue lighten-3"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ student.firstName }} {{ student.lastName }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" class="ma-2 white--text px-3" @click="closeDialog">
        Back
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "SectionInfo",
  props: {
    sectionData: {
      type: Object,
      default: () => ({
        id: "",
        name: "",
        gradeLevel: "",
        teacherName: "",
      }),
    },
  },
  data() {
    return {
      sectionsAvailable: [],
      selectedStudents: [],
      sectionId: "",
      loadingStudents: false,
      transferDialog: false,
      transferValid: false,
      selectedAll: false,
    };
  },
  computed: {
    ...mapGetters({
      studentsEnrolled: "usersModule/getStudents",
      sections: "sectionsModule/getSectionsAutocomplete",
    }),
  },
  methods: {
    openTransferDialog() {
      this.sectionsAvailable = this.sections.filter(
        (section) => section.value != this.sectionData.id
      );

      this.transferDialog = true;
    },
    toggleSelectAll() {
      if (this.selectedAll) {
        this.transferValid = true;
        this.selectedStudents = this.studentsEnrolled.map(
          (student) => student.id
        );
      } else {
        this.transferValid = false;
        this.selectedStudents = [];
      }
    },
    hasSelectedStudents(selected) {
      if (selected.length === this.studentsEnrolled.length) {
        this.selectedAll = true;
        this.transferValid = true;
      } else {
        if (selected.length > 0) {
          this.transferValid = true;
          this.selectedAll = false;
        } else {
          this.transferValid = false;
        }
      }
    },
    async transferSection() {
      if (this.transferValid) {
        const studentIds = this.selectedStudents.map((studentId) => studentId);
        const payload = {
          sectionId: this.sectionId,
          studentIds: studentIds,
        };

        this.loadingStudents = true;
        await this.$store.dispatch("sectionsModule/transferSection", payload);
        this.transferDialog = false;
        await this.$store.dispatch("usersModule/getUsers", {
          role: "student",
          sectionId: this.sectionData.id,
        });

        this.clearInputs();
      }
    },
    clearInputs() {
      this.transferValid = false;
      this.loadingStudents = false;
      this.selectedAll = false;
      this.sectionsAvailable = [];
      this.selectedStudents = [];
      this.sectionId = "";
    },
    closeDialog() {
      this.$emit("closeDialog", "view");
    },
  },
};
</script>
<style scoped>
.word-break-unset {
  word-break: unset;
}
</style>

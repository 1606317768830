<template>
  <v-card>
    <v-card-title class="main-red-bg white--text">
      <span class="text-h5">{{ sectionFormTitle }}</span>
    </v-card-title>
    <v-divider class="mb-2"></v-divider>
    <v-card-text>
      <v-form v-model="isSectionFormValid" ref="sectionForm">
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="sectionFormData.name"
                label="Name"
                :rules="[validators.required]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="sectionFormData.gradeLevel"
                type="number"
                label="Grade Level"
                :rules="[validators.required, validGradeLevel]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="sectionFormData.teacherId"
                label="Adviser"
                cache-items
                clearable
                :items="getTeachersAutocomplete"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" class="ma-2 white--text px-3" @click="closeDialog">
        Cancel
      </v-btn>
      <v-btn
        :disabled="!isSectionFormValid"
        :loading="loadingSubmit"
        @click="submitSectionForm"
        color="success"
        class="ma-2 white--text px-3"
      >
        <v-icon left dark> mdi-send </v-icon>
        {{ formActionLabel }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import validators from "@/helpers/validationHelper";
import { mapGetters } from "vuex";
export default {
  name: "SectionForm",
  props: {
    sectionFormTitle: {
      type: String,
      default: "Add Section",
    },
    isUpdating: {
      type: Boolean,
      default: false,
    },
    sectionData: {
      type: Object,
      default: () => ({
        name: "",
        gradeLevel: "",
        teacherId: "",
      }),
    },
  },
  data() {
    return {
      isSectionFormValid: false,
      loadingSubmit: false,
      validators: validators,
      sectionFormData: {
        name: "",
        gradeLevel: "",
        teacherId: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      getTeachersAutocomplete: "usersModule/getTeachersAutocomplete",
    }),
    formActionLabel: function () {
      return this.isUpdating ? "Update" : "Submit";
    },
  },
  methods: {
    validGradeLevel(gradeLevel) {
      return gradeLevel > 0 && gradeLevel < 13 ? true : "Invalid Grade Level";
    },
    async submitSectionForm() {
      this.loadingSubmit = true;
      const revalidatedForm = this.$refs.sectionForm.validate();
      if (revalidatedForm) {
        await this.$store.dispatch("sectionsModule/submitSectionForm", {
          section: this.sectionFormData,
          isUpdating: this.isUpdating,
        });
      }
      this.loadingSubmit = false;
      this.closeDialog();
    },
    closeDialog() {
      this.$refs.sectionForm.reset();
      this.sectionFormData = {
        name: "",
        gradeLevel: "",
        teacherId: "",
      };
      this.$store.commit("sectionsModule/clearSection");
      this.$emit("closeDialog", this.isUpdating ? "edit" : "add");
    },
  },
  watch: {
    sectionData: {
      handler(newSectionFormData) {
        if (this.isUpdating) {
          this.sectionFormData = newSectionFormData;
        }
      },
      immediate: true,
    },
  },
};
</script>

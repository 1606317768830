const validators = {
  required: (value) => {
    return value === "" || value === null ? "This field is required" : true;
  },
  min: (value) => {
    return value > 0 || "Minimum of 1";
  },
  requiredNumberWithZero: (value) => {
    const numberOnly = new RegExp("^[0-9]*$");

    return numberOnly.test(value) || "Please input a valid number";
  },
  requiredNumber: (value) => {
    const numberOnly = new RegExp("^[0-9]*$");

    return (
      (numberOnly.test(value) && value > 0) || "Please input a valid number"
    );
  },
  requireBlank: (value) => {
    return value.indexOf("_") === -1 ? 'Please provide a blank "_"' : true;
  },
  validEmail: (value) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value) ? true : "Please enter a valid email";
  },
  validPassword: (value) => {
    var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    return re.test(value)
      ? true
      : "Please enter atleast 8 characters, 1 upper case letter and 1 number";
  },
  validPhone: (value) => {
    var re = /^\+?[0-9][0-9]{7,14}$/;
    return re.test(value) ? true : "Please enter a valid phone number";
  },
  validYear: (value) => {
    const currentYear = new Date().getFullYear();
    return value < currentYear ? `Please input year from ${currentYear}` : true;
  },
  validSemester: (value) => {
    if (value) {
      return value > 0 && value < 4 ? true : "Invalid Semester";
    }

    return true;
  },
};

export default validators;

<template>
  <v-card class="elevation-2">
    <v-dialog v-model="sectionViewDialog" max-width="600px">
      <SectionInfo
        :sectionData="sectionData"
        @closeDialog="closeDialog($event)"
      />
    </v-dialog>
    <v-dialog v-model="sectionAddDialog" max-width="600px">
      <SectionForm
        sectionFormTitle="Add Section"
        @closeDialog="closeDialog($event)"
      />
    </v-dialog>
    <v-dialog v-model="sectionEditDialog" max-width="600px">
      <SectionForm
        sectionFormTitle="Update Section"
        :isUpdating="true"
        :sectionData="sectionData"
        @closeDialog="closeDialog($event)"
      />
    </v-dialog>
    <v-dialog v-model="sectionDeleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="main-red-bg white--text">
          <span class="text-h5">Delete Section</span>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-text>
          <span class="text-h6">Are you sure you want to delete section ?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="ma-2 white--text px-3"
            @click="closeDialog('delete')"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="deleteSection"
            :loading="deletingSection"
            color="warning"
            class="ma-2 white--text px-3"
          >
            <v-icon left dark> mdi-delete </v-icon>
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title>
      <v-row>
        <v-col
          :class="$vuetify.breakpoint.xs ? 'justify-center' : ''"
          class="d-flex align-center"
          cols="12"
          sm="6"
        >
          <h2 class="mx-2">Sections Table</h2>
        </v-col>
        <v-col
          :class="$vuetify.breakpoint.xs ? 'justify-center' : ''"
          class="d-flex justify-end"
          cols="12"
          sm="6"
        >
          <v-btn-toggle>
            <v-btn
              color="success"
              class="px-4 remove-toggled-state"
              light
              medium
              @click="openDialog({ action: 'add', sectionId: null })"
            >
              Add Section
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col class="d-flex align-center" cols="12" sm="6">
          <FilterSelect
            filterLabel="Grade"
            :filterOptions="sectionGradeLevels"
            @change="filterSectionsByGrade = $event"
          />
        </v-col>
        <v-col class="d-flex align-center" cols="12" sm="6">
          <SearchQuery @search="searchInputQuery = $event" />
        </v-col>
      </v-row>
    </v-card-title>
    <SectionList
      :searchInput="searchInputQuery"
      :filterSectionsByGrade="filterSectionsByGrade"
      @openDialog="openDialog($event)"
    />
  </v-card>
</template>

<script>
import SearchQuery from "@/components/SearchQuery";
import FilterSelect from "@/components/FilterSelect";
import SectionList from "@/components/SectionList";
import SectionForm from "@/components/SectionForm";
import SectionInfo from "@/components/SectionInfo";
import { mapGetters } from "vuex";
export default {
  metaInfo: {
    title: "Sections",
  },
  name: "SectionIndex",
  components: {
    SearchQuery,
    FilterSelect,
    SectionList,
    SectionForm,
    SectionInfo,
  },
  data() {
    return {
      filterSectionsByGrade: "",
      searchInputQuery: "",
      sectionGradeLevels: ["7", "8", "9", "10", "11", "12"],
      sectionViewDialog: false,
      sectionAddDialog: false,
      sectionEditDialog: false,
      sectionDeleteDialog: false,
      deletingSection: false,
    };
  },
  computed: {
    ...mapGetters({
      sectionData: "sectionsModule/getSection",
    }),
  },
  methods: {
    async openDialog(dialogObject) {
      if (dialogObject.sectionId) {
        await this.$store.dispatch(
          "sectionsModule/getSectionById",
          dialogObject.sectionId
        );
      }
      switch (dialogObject.action) {
        case "view":
          this.sectionViewDialog = true;
          await this.$store.dispatch("usersModule/getUsers", {
            role: "student",
            sectionId: dialogObject.sectionId,
          });
          break;
        case "add":
          this.sectionAddDialog = true;
          break;
        case "edit":
          this.sectionEditDialog = true;
          break;
        case "delete":
          this.sectionDeleteDialog = true;
          break;
      }
    },
    closeDialog(dialog) {
      switch (dialog) {
        case "view":
          this.sectionViewDialog = false;
          break;
        case "add":
          this.sectionAddDialog = false;
          break;
        case "edit":
          this.sectionEditDialog = false;
          break;
        case "delete":
          this.sectionDeleteDialog = false;
          break;
      }
    },
    async deleteSection() {
      const sectionId = this.sectionData.id;
      this.deletingSection = true;
      await this.$store.dispatch("sectionsModule/deleteSectionById", sectionId);
      this.deletingSection = false;
      this.closeDialog("delete");
    },
  },
};
</script>
<style scoped>
.remove-toggled-state {
  color: white !important;
}
</style>
